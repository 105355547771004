<template>
  <div>
    <div>
      <derive titleStr="导出考试小题分" :data="dataArr" :onDerive="onDerive" :formData="dataForm" :infoArr="placeholder"></derive>
    </div>
    <div style="width: calc(100% - 100rem);height: 1rem;background: #EFEFEF;margin: 24rem 50rem 0;"></div>
<!--    <div>-->
<!--      <derive titleStr="导出学生当前目标分数" :data="dataArrTo" :onDerive="onDeriveTo" :formData="dataFormTo" :infoArr="placeholder"-->
<!--              info-str="选择后即可导出此次考试后学生设定的目标分数">-->
<!--      </derive>-->
<!--    </div>-->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import derive from '@/components/Derive';

export default {
	_config:{"route":{"path":"list","meta":{"title":"设置"}}},
  //import引入的组件需要注入到对象中才能使用",
  components: {
    derive,
  },
  data() {
    //这里存放数据",
    return {
      dataArr: [
        {
          label: '选择考试',
          value: '',
          key: 'exam_id',
          options: [],
          onChange: (val) => {
            this.dataArr[0].options.forEach(item => {
              if (item.value === val) {
                this.dataStr = item.label;
              }
            });
            this.dataForm.school_id = '';
            this.dataForm.subject_id = '';
            this.dataForm.grade_id = '';
            this.dataForm.class_id = '';
            this.dataArr[1].options = [];
            this.dataArr[1].disabled = false;
            this.dataArr[2].disabled = true;
            this.dataArr[3].disabled = true;
            this.dataArr[4].disabled = true;
            this.dataArr[4].options = [];
            this.$_axios.get('site/exam-subject?exam_id=' + val).then(res => {
              res.data.data.forEach(item => {
                this.dataArr[1].options.push({
                  label: item.child[0].subject_name,
                  value: item.child[0].id,
                });
              });
            });
            this.dataArr[0].options.forEach(item => {
              if (item.value === val) {
                this.dataArr[2].options = JSON.parse(JSON.stringify(item).replace(/grade_name/g, 'label').replace(/grade_id/g, 'value').replace(/school_name/g, 'label').replace(/school_id/g, 'value')).school_data;
                this.dataArr[3].options = JSON.parse(JSON.stringify(item).replace(/grade_name/g, 'label').replace(/grade_id/g, 'value').replace(/school_name/g, 'label').replace(/school_id/g, 'value')).grade_data;
              }
            });
          },
          disabled: false,
        }, {
          label: '选择科目',
          value: '',
          key: 'subject_id',
          options: [],
          disabled: true,
          onChange: () => {
            this.dataForm.grade_id = '';
            this.dataForm.class_id = '';
            this.dataForm.school_id = '';
            this.dataArr[2].disabled = false;
            this.dataArr[3].disabled = true;
            this.dataArr[4].disabled = true;
          },
        }, {
          label: '选择校区',
          value: '',
          key: 'school_id',
          options: [],
          disabled: true,
          onChange: () => {
            this.dataForm.grade_id = '';
            this.dataForm.class_id = '';
            this.dataArr[3].disabled = false;
            this.dataArr[4].disabled = true;
          },
        }, {
          label: '选择年级',
          value: '',
          key: 'grade_id',
          options: [],
          disabled: true,
          onChange: (val) => {
            let data = {
              exam_id: this.dataForm.exam_id,
              school_id: this.dataForm.school_id,
              grade_id: val,
              subject_id: this.dataForm.subject_id,
            };
            this.$_axios2.get('/api/common/screen/exam-in-class', {params: data}).then(res => {
              this.dataArr[4].options = JSON.parse(JSON.stringify(res.data.data).replace(/class_name/g, 'label').replace(/id/g, 'value'));
              this.dataArr[4].options.unshift({
                label: '全部班级',
                value: 0,
                type: 0,
              });
              this.dataArr[4].disabled = false;
              this.dataForm.class_id = '';
            });
          },
        }, {
          label: '选择班级',
          value: '',
          key: 'class_id',
          options: [],
          disabled: true,
        },
      ],
      dataArrTo: [
        {
          label: '选择考试',
          value: '',
          key: 'exam_id',
          options: [],
          onChange: (val) => {
            this.dataArrTo[0].options.forEach(item => {
              if (item.value === val) {
                this.dataStrTo = item.label;
              }
            });
            this.dataFormTo.school_id = '';
            this.dataFormTo.subject_id = '';
            this.dataFormTo.grade_id = '';
            this.dataFormTo.class_id = '';
            this.dataArrTo[1].options = [];
            this.dataArrTo[1].disabled = false;
            this.dataArrTo[2].disabled = true;
            this.dataArrTo[3].disabled = true;
            this.dataArrTo[4].disabled = true;
            this.dataArrTo[4].options = [];
            this.$_axios.get('site/exam-subject?exam_id=' + val).then(res => {
              res.data.data.forEach(item => {
                this.dataArrTo[1].options.push({
                  label: item.child[0].subject_name,
                  value: item.child[0].id,
                });
              });
              this.dataArrTo[1].options.unshift({
                label: '全部科目',
                value: 0,
              });
            });
            this.dataArrTo[0].options.forEach(item => {
              if (item.value === val) {
                this.dataArrTo[2].options = JSON.parse(JSON.stringify(item).replace(/grade_name/g, 'label').replace(/grade_id/g, 'value').replace(/school_name/g, 'label').replace(/school_id/g, 'value')).school_data;
                this.dataArrTo[3].options = JSON.parse(JSON.stringify(item).replace(/grade_name/g, 'label').replace(/grade_id/g, 'value').replace(/school_name/g, 'label').replace(/school_id/g, 'value')).grade_data;
              }
            });
          },
        }, {
          label: '选择科目',
          value: '',
          key: 'subject_id',
          options: [],
          disabled: true,
          onChange: () => {
            this.dataFormTo.grade_id = '';
            this.dataFormTo.class_id = '';
            this.dataFormTo.school_id = '';
            this.dataArrTo[2].disabled = false;
            this.dataArrTo[3].disabled = true;
            this.dataArrTo[4].disabled = true;
          },
        }, {
          label: '选择校区',
          value: '',
          key: 'school_id',
          options: [],
          onChange: (val) => {
            this.dataFormTo.grade_id = '';
            this.dataFormTo.class_id = '';
            this.dataArrTo[3].disabled = false;
            this.dataArrTo[4].disabled = true;
            this.$_axios.get('site/role-class?school=' + val + '&exam=' + this.dataArrTo[0].value).then(res => {
              this.dataArrTo[4].options = JSON.parse(JSON.stringify(res.data.data).replace(/class_name/g, 'label').replace(/id/g, 'value'));
              this.dataArrTo[4].options.unshift({
                label: '全部班级',
                value: 0,
                type: 0,
              });
              this.dataArrTo[3].disabled = false;
            });
          },
          disabled: true,
        }, {
          label: '选择年级',
          value: '',
          key: 'grade_id',
          options: [],
          disabled: true,
          onChange: (val) => {
            let data = {
              exam_id: this.dataFormTo.exam_id,
              school_id: this.dataFormTo.school_id,
              grade_id: val,
              subject_id: this.dataFormTo.subject_id,
            };
            this.$_axios2.get('/api/common/screen/exam-in-class', {params: data}).then(res => {
              this.dataArrTo[4].options = JSON.parse(JSON.stringify(res.data.data).replace(/class_name/g, 'label').replace(/id/g, 'value'));
              this.dataArrTo[4].options.unshift({
                label: '全部班级',
                value: 0,
                type: 0,
              });
              this.dataFormTo.class_id = '';
              this.dataArrTo[4].disabled = false;
            });
          },
        }, {
          label: '选择班级',
          value: '',
          key: 'class_id',
          options: [],
          disabled: true,
        },
      ],
      placeholder: [
        '请选择考试名称',
        '请选择考试科目',
        '请选择校区',
        '请选择年级',
        '请选择班级',
      ],
      dataForm: {
        exam_id: '',
        subject_id: '',
        school_id: '',
        grade_id: '',
        class_id: '',
        type: 0,
      },
      dataFormTo: {
        exam_id: '',
        subject_id: '',
        school_id: '',
        grade_id: '',
        class_id: '',
        type: 0,
      },
      dataStr: '',
      dataStrTo: '',
    };
  },
  //方法集合",
  methods: {
    onDerive() {
      for (const argumentsKey in this.dataForm) {
        if (this.dataForm[argumentsKey] === '') {
          this.$message.error('选择项不能为空');
          return;
        }
      }
      this.dataArr[4].options.forEach(item => {
        if (item.value === this.dataForm.class_id) {
          this.dataForm.type = item.type;
        }
      });
      this.$_axios2.post('/api/student/excel-exam/subjects-export', {
            ...this.dataForm,
          },
          {
            responseType: 'blob',
          }).then(res => {
            if (res.data.status == null)
              this.downloadFile(res, '考试小题分', this.dataStr);
      });
    },
    onDeriveTo() {
      for (const argumentsKey in this.dataFormTo) {
        if (this.dataFormTo[argumentsKey] === '') {
          this.$message.error('选择项不能为空');
          return;
        }
      }
      this.dataArrTo[4].options.forEach(item => {
        if (item.value === this.dataFormTo.class_id) {
          this.dataFormTo.type = item.type;
        }
      });
      this.$_axios2.post('/api/student/excel-exam/target-export', {
            ...this.dataFormTo,
          },
          {
            responseType: 'blob',
          }).then(res => {
        this.downloadFile(res, '学生成绩目标分数', this.dataStrTo);
      });
    },
    //	文件下载公共方法
    downloadFile(res, title, titleTo) {
      const filename = '【' + titleTo + '】' + title + '.csv';
      const blob = new Blob([res.data]);
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = decodeURIComponent(filename);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    },
  },
  //生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  //生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.$_axios.post('/site/get-query-cond').then(res => {
      this.dataArr[0].options = JSON.parse(JSON.stringify(res.data.data).replace(/exam_name/g, 'label').replace(/exam_id/g, 'value'));
    });
    this.$_axios.post('/site/get-query-cond', {scope: 2}).then(res => {
      this.dataArrTo[0].options = JSON.parse(JSON.stringify(res.data.data).replace(/exam_name/g, 'label').replace(/exam_id/g, 'value'));
    });
  },
};

</script>

<style scoped>

</style>
